import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from 'flowbite-react';
import { Paragraph2XlGreenMargin } from '../../../Component/Paragraph';
import { InputField, InputFieldSecondary } from '../../../Component/InputField';
import { ButtonsPrimary } from '../../../Component/Buttons';

const Transaction = () => {
    return (
        <main className='px-4'>
            <div className="my-10">
                <div className="bg-lightBackground py-5 rounded-3xl shadow-2xl">
                    <Paragraph2XlGreenMargin content={"Customer Billing"} />
                    <div className="overflow-x-auto font-Poppins mt-3">
                        <Table striped >
                            <TableHead className=" font-Poppins text-sm capitalize text-white bg-green dark:text-white">
                                <TableHeadCell>User ID</TableHeadCell>
                                <TableHeadCell>Username</TableHeadCell>
                                <TableHeadCell>Property address</TableHeadCell>
                                <TableHeadCell>Total Overdue Bills</TableHeadCell>
                                <TableHeadCell>No of Flats</TableHeadCell>
                                <TableHeadCell>Amount(N)</TableHeadCell>
                                <TableHeadCell>Billing Action</TableHeadCell>
                                <TableHeadCell>Status</TableHeadCell>
                            </TableHead>
                            <TableBody className="divide-y font-Poppins ">
                                <TableRow className="odd:bg-tableBackground">

                                    <TableCell>000001</TableCell>
                                    <TableCell>Olankunle_22</TableCell>
                                    <TableCell>34 Sunrise Avenue, Agege...</TableCell>
                                    <TableCell>Cleared</TableCell>
                                    <TableCell>3</TableCell>
                                    <TableCell>
                                        <InputFieldSecondary type={"number"} />
                                    </TableCell>
                                    <TableCell>
                                        <ButtonsPrimary name={"Bill"} />
                                    </TableCell>
                                    <TableCell>
                                        <span className=' text-success'>Billed</span>
                                    </TableCell>

                                </TableRow>
                                <TableRow className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <TableCell>000001</TableCell>
                                    <TableCell>Olankunle_22</TableCell>
                                    <TableCell>34 Sunrise Avenue, Agege...</TableCell>
                                    <TableCell>Cleared</TableCell>
                                    <TableCell>3</TableCell>
                                    <TableCell>
                                        <InputFieldSecondary type={"number"} />
                                    </TableCell>
                                    <TableCell>
                                        <ButtonsPrimary name={"Bill"} />
                                    </TableCell>
                                    <TableCell>
                                        <span className=' text-error'>Unbilled</span>
                                    </TableCell>
                                </TableRow>

                                <TableRow className="odd:bg-tableBackground">

                                    <TableCell>000001</TableCell>
                                    <TableCell>Olankunle_22</TableCell>
                                    <TableCell>34 Sunrise Avenue, Agege...</TableCell>
                                    <TableCell>Cleared</TableCell>
                                    <TableCell>3</TableCell>
                                    <TableCell>
                                        <InputFieldSecondary type={"number"} />
                                    </TableCell>
                                    <TableCell>
                                        <ButtonsPrimary name={"Bill"} />
                                    </TableCell>
                                    <TableCell>
                                        <span className=' text-success'>Billed</span>
                                    </TableCell>

                                </TableRow>
                                <TableRow className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <TableCell>000001</TableCell>
                                    <TableCell>Olankunle_22</TableCell>
                                    <TableCell>34 Sunrise Avenue, Agege...</TableCell>
                                    <TableCell>Cleared</TableCell>
                                    <TableCell>3</TableCell>
                                    <TableCell>
                                        <InputFieldSecondary type={"number"} />
                                    </TableCell>
                                    <TableCell>
                                        <ButtonsPrimary name={"Bill"} />
                                    </TableCell>
                                    <TableCell>
                                        <span className=' text-error'>Unbilled</span>
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
            <div className="my-5">
                <div className="bg-lightBackground py-5 rounded-3xl shadow-2xl">
                    <Paragraph2XlGreenMargin content={"Recent Transactions"} />
                    <div className="overflow-x-auto font-Poppins mt-3">
                        <Table striped >
                            <TableHead className=" font-Poppins text-sm capitalize text-white bg-green dark:text-white">
                                <TableHeadCell>Date & Time</TableHeadCell>
                                <TableHeadCell>Username</TableHeadCell>
                                <TableHeadCell>Address</TableHeadCell>
                                <TableHeadCell>Amount</TableHeadCell>
                                <TableHeadCell>Bill Type</TableHeadCell>
                                <TableHeadCell>Mode of Payment</TableHeadCell>
                                <TableHeadCell>Status</TableHeadCell>
                                <TableHeadCell>Total Overdue
                                    Bills
                                </TableHeadCell>
                            </TableHead>
                            <TableBody className="divide-y font-Poppins ">
                                <TableRow className="odd:bg-tableBackground">

                                    <TableCell>Today,10:30PM</TableCell>
                                    <TableCell>Olankunle_22</TableCell>
                                    <TableCell>34 Sunrise Avenue, Agege...</TableCell>
                                    <TableCell>N13,100.00</TableCell>
                                    <TableCell>Current bill</TableCell>
                                    <TableCell>debit card</TableCell>
                                    <TableCell>
                                        <span className='text-success'>Succesful</span>
                                    </TableCell>
                                    <TableCell>
                                        Cleared
                                    </TableCell>

                                </TableRow>
                                <TableRow className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <TableCell>
                                        02, Mar 23,11:10PM
                                    </TableCell>
                                    <TableCell>Segun_10</TableCell>
                                    <TableCell>34 Sunrise Avenue, Agege...</TableCell>
                                    <TableCell>N57,100.00</TableCell>
                                    <TableCell>Current bill</TableCell>
                                    <TableCell>bank transfer</TableCell>
                                    <TableCell>
                                        <span className='text-pending'>Pending</span>
                                    </TableCell>
                                    <TableCell>Cleared</TableCell>
                                </TableRow>

                                <TableRow className="odd:bg-tableBackground">

                                    <TableCell>10, Mar 23, 04:10PM</TableCell>
                                    <TableCell>Olankunle_22</TableCell>
                                    <TableCell>34 Sunrise Avenue, Agege...</TableCell>
                                    <TableCell>N100,100.00</TableCell>
                                    <TableCell>Current bill</TableCell>
                                    <TableCell>debit card</TableCell>
                                    <TableCell>
                                        <span className='text-failed'>Failed</span>
                                    </TableCell>
                                    <TableCell>
                                        N75,000.00
                                    </TableCell>

                                </TableRow>
                                <TableRow className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <TableCell>
                                        02, Mar 23,11:10PM
                                    </TableCell>
                                    <TableCell>Segun_10</TableCell>
                                    <TableCell>34 Sunrise Avenue, Agege...</TableCell>
                                    <TableCell>N57,100.00</TableCell>
                                    <TableCell>Current bill</TableCell>
                                    <TableCell>bank transfer</TableCell>
                                    <TableCell>
                                        <span className='text-success'>Succesful</span>
                                    </TableCell>
                                    <TableCell>Cleared</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Transaction