import { Card } from 'flowbite-react'
import React from 'react'
import { useSelector } from 'react-redux';
import { selectUser } from '../../Features/authSlice';
import { ParagraphLarge, ParagraphNoMargin } from '../Paragraph'

const ProfileCard = () => {
    // const user = useSelector(selectUser);
    const user =  JSON.parse(sessionStorage.getItem('AUTH_USERDETAILS'));
    return (
        <Card className="max-w-sm border-transparent text-center">
            <div className="">
                <ParagraphNoMargin content={"Hello!"} />
                <ParagraphLarge content={user ? user.firstname : 'Name'} />
            </div>
            <div className="">
                <ParagraphNoMargin content={"Total Bills"} />
                <ParagraphLarge content={"N 100,000,000.00"} />
            </div>

        </Card>
    )
}

export default ProfileCard