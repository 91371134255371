import React, { useEffect, useState } from 'react'
import { Tab } from "@headlessui/react";
import { useFormik } from 'formik';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { Paragraph2Green, Paragraph2XlGreenMargin, ParagraphExtraSmallError, ParagraphTextSettings } from '../../../Component/Paragraph'
import baseURL from '../../../utils/apiConfig';
import { InputFieldSecondaryGreen } from '../../../Component/InputField';
import { ButtonsPrimary } from '../../../Component/Buttons';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const validate = values => {
    const errors = {};
    // if (!values.propertyTypeId) {
    //     errors.propertyTypeId = 'Property Type is Required';
    // }

    // if (!values.amount) {
    //     errors.amount = 'Amount is Required';
    // }

    return errors;
};



const Pricing = () => {

    const [residentials, setResidentials] = useState([]);
    const [loading, SetLoading] = useState(false)
    const [commercial, setCommercials] = useState([]);
    const [bills, setBills] = useState([]);
    const [selectproperty, setSelectedProperty] = useState('');
    const [selectpropertyAmount, setSelectedPropertyAmount] = useState('');
    const [billsID, setBillID] = useState('');
    const [errors, setError] = useState({});
    const operator = JSON.parse(sessionStorage.getItem('AUTH_ADMIN_DETAILS'));

    const someValues = {
        propertyTypeId: selectproperty,
        amount: selectpropertyAmount
    }

    const handlePropertyTypeChange = (event) => {
        const selectedId = parseInt(event.target.value);
        setSelectedProperty(selectedId)
        const selectedProperty = bills.find(bill => bill.propertyTypeId === selectedId);
        if (selectedProperty) {
            const amount = selectedProperty.amount;
            const id = selectedProperty.id;
            setBillID(id)
            setSelectedPropertyAmount(amount)
            console.log(amount); // Or do whatever you want with the amount
        }else{
            setSelectedPropertyAmount('')
            setBillID('')
        }
        setError('');
    }

    const handlePriceChange = (e) => {
        setSelectedPropertyAmount(e.target.value)
        setError('');
    }

    const formik = useFormik({
        initialValues: {
            // amount: selectpropertyAmount,
        },
        validate,
        onSubmit: values => {
            try {
                const data = {
                    ...values,
                    'pspOperatorId': operator.pspOperatorId,
                    ...someValues
    
                }

                if (selectproperty == '') {
                    setError({
                        propertyTypeId: 'Property is required'
                    })
                    return;
                }
                if (selectpropertyAmount == '') {
                    setError({
                        amount: 'Amount is required'
                    })
                    return;
                }

                if (billsID == '') {
                    axios.post(baseURL + 'billSetup', data, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then((res) => {
                        console.log(res);
                        SetLoading(false)
                        const message = res.data?.message;
                        const errorState = res.data?.errorState;
                        if (!errorState) {
                            toast.success(message, { autoClose: 5000 });
                            setTimeout(() => {
                                window.location.reload();
                            }, 3000)
                        } else {
                            toast.error(message, { autoClose: 5000 });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        SetLoading(false)
                        const message = err.response.data?.message;
                        toast.error(message, { autoClose: 7000 });
                    });
                }else{
                    axios.put(baseURL + 'billSetup/' + billsID, data, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then((res) => {
                        console.log(res);
                        SetLoading(false)
                        const message = res.data?.message;
                        const errorState = res.data?.errorState;
                        if (!errorState) {
                            toast.success(message, { autoClose: 5000 });
                            setTimeout(() => {
                                window.location.reload();
                            }, 3000)
                        } else {
                            toast.error(message, { autoClose: 5000 });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        SetLoading(false)
                        const message = err.response.data?.message;
                        toast.error(message, { autoClose: 7000 });
                    });
                }
              
              
            } catch (error) {
                console.log(error);
            }
        },
    });

    const HandleFetchPropety = () => {
        axios.get(baseURL + 'propertyType', {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const responseData = res.data?.data;
                // Filter objects based on isResidential property
                const residentialProperties = responseData.filter(item => item.isResidential === 1);
                const commercialProperties = responseData.filter(item => item.isResidential === 0);

                // Sort arrays based on id
                residentialProperties.sort((a, b) => a.id - b.id);
                commercialProperties.sort((a, b) => a.id - b.id);

                setResidentials(residentialProperties);
                setCommercials(commercialProperties);

            })
            .catch((err) => {
                console.log(err);
            });
    };


    const FetchAllBills = () => {
        axios.get(baseURL + 'billSetup/getBillByPspOperator/' + operator.pspOperatorId, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                // console.log("ills fetched property", res);
                const data = res.data.data;
                console.log("Bills", data);
                setBills(data);

            })
            .catch((err) => {
                console.log(err);
            });
    }
    useEffect(() => {
        HandleFetchPropety();
        FetchAllBills();
    }, [])

    return (
        <>
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <div className='w-full border border-transparent rounded-lg ring-2 ring-transparent shadow-2xl px-5 py-14'>
                        <ToastContainer />
                        <Paragraph2XlGreenMargin content={"Pricing"} />
                        <ParagraphTextSettings content={"Setup property price"} />
                        <div className="">
                            <div className="mt-8">
                                <Tab.Group>
                                    <Tab.List className="flex space-x-1 rounded-full bg-lightgrayer">
                                        <Tab
                                            className={({ selected }) =>
                                                classNames(
                                                    "w-full rounded-full py-2.5 text-base font-medium leading-5 font-sans",
                                                    "focus:outline-none focus:ring-0",
                                                    selected ? "bg-green shadow text-white" : "text-white"
                                                )
                                            }
                                        >
                                            Residential
                                        </Tab>

                                        <Tab
                                            className={({ selected }) =>
                                                classNames(
                                                    "w-full rounded-full py-2.5 text-base font-medium leading-5 font-sans",
                                                    "focus:outline-none focus:ring-0",
                                                    selected ? "bg-green shadow text-white" : "text-white"
                                                )
                                            }
                                        >
                                            Commercial
                                        </Tab>
                                    </Tab.List>
                                    <Tab.Panels className="mt-2">
                                        <Tab.Panel>
                                            {/* <p>this part A</p> */}
                                            <div className="mt-10 mx-4">
                                                <select id="residential" className="border-2 px-2 py-3 pl-5  bg-white shadow-sm rounded-lg block font-Poppins w-full h-14 text-green    text-base mt-1 ring-gray  placeholder:text-green focus:outline-none focus:ring-2 focus:border-purple focus:border-transparent focus:ring-gray" name={"propertyTypeId"} onChange={handlePropertyTypeChange} value={selectproperty}>
                                                    <option value="" >Residential Type</option>
                                                    {residentials?.map((res) => (
                                                        <option key={res.id} value={res.id}>
                                                            {res.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.propertyTypeId ? <ParagraphExtraSmallError content={errors.propertyTypeId} /> : null}
                                            </div>
                                        </Tab.Panel>

                                        <Tab.Panel>
                                            <div className="mt-10 mx-4">
                                                <select id="commecial" className="border-2 px-2 py-3 pl-5  bg-white shadow-sm rounded-lg block font-Poppins w-full h-14 text-green    text-base mt-1 ring-gray  placeholder:text-green focus:outline-none focus:ring-2 focus:border-purple focus:border-transparent focus:ring-gray" name={"propertyTypeId"}
                                                    onChange={handlePropertyTypeChange} value={selectproperty}
                                                >
                                                    <option value="" >Commercial Type</option>
                                                    {commercial?.map((com) => (
                                                        <option key={com.id} value={com.id}>
                                                            {com.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.propertyTypeId ? <ParagraphExtraSmallError content={errors.propertyTypeId} /> : null}
                                            </div>
                                        </Tab.Panel>
                                    </Tab.Panels>
                                </Tab.Group>
                            </div>
                            <div className="mt-8 px-4">
                                <InputFieldSecondaryGreen placeholder={"Amount (000,000.00)"} type={"number"} name={"amount"} onChange={handlePriceChange} value={selectpropertyAmount} />
                                {errors.amount ? <ParagraphExtraSmallError content={errors.amount} /> : null}
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 flex w-1/6">
                        <ButtonsPrimary name={billsID ? "Update Bill" : "Save"} type={'submit'} loading={loading} />
                    </div>
                </form>
            </div>

        </>
    )
}

export default Pricing