import React, { useEffect, useState } from 'react'
import { FaHouse } from "react-icons/fa6";
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from 'flowbite-react';
import { IoPeople } from "react-icons/io5";
import AdminCard from '../../../Component/Card/AdminCard'
import { Paragraph2XlGreenMargin } from '../../../Component/Paragraph';
import axios from 'axios';
import baseURL from '../../../utils/apiConfig';
import { ButtonsPrimary } from '../../../Component/Buttons';
import CustomerModal from '../../../Component/Modal/CustomerModal';

const Records = () => {
    const [AllUsers, setAllUsers] = useState([])
    const operator = JSON.parse(sessionStorage.getItem('AUTH_ADMIN_DETAILS'));
    // console.log(operator);
    const [currentPage, setCurrentPage] = useState(1);
    const [transactionsPerPage] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedCustomerPType, setSelectedCustomerPType] = useState(null);


    const handleToggleOpen = (customerData, propertyType) => {
        setOpenModal(true)
        setSelectedCustomer(customerData);
        setSelectedCustomerPType(propertyType)
    }
    const HandleAllUserRecords = () => {
        axios.get(baseURL + 'customer/psp-operator/' + operator.pspOperatorId, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const responseData = res.data?.data;
                // console.log(responseData);
                setAllUsers(responseData)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        HandleAllUserRecords()
    }, []);


    const indexOfLastTransaction = currentPage * transactionsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
    const currentTransactions =
        AllUsers && AllUsers.length
            ? AllUsers.slice(indexOfFirstTransaction, indexOfLastTransaction)
            : [];

    // console.log("currentTransactions", currentTransactions);
    // console.log("currentTransactions", AllUsers.length);
    const paginate = (pageNumber) => {
        if (pageNumber < 1) {
            setCurrentPage(1);
        } else if (
            pageNumber > Math.ceil(AllUsers.length / transactionsPerPage)
        ) {
            setCurrentPage(Math.ceil(AllUsers.length / transactionsPerPage));
        } else {
            setCurrentPage(pageNumber);
        }
    };


    // {
    //     "errorState": false,
    //     "error": "",
    //     "message": "Customers Found",
    //     "data": [
    //         {
    //             "id": 3,
    //             "propertyCode": "WFLAALIK000003",
    //             "pLatitude": "6.604661",
    //             "pLongitude": "3.2595955",
    //             "propertyAddress": "36 kole St, Idimu, Lagos 102213, Lagos, Nigeria",
    //             "stateId": 1,
    //             "lgaId": 3,
    //             "wardId": 27,
    //             "propertyTypeId": 4,
    //             "pspOperatorId": 3,
    //             "created_at": "2024-03-24T17:57:28.000000Z",
    //             "updated_at": "2024-03-24T17:57:28.000000Z",
    //             "customer_properties": [
    //                 {
    //                     "id": 4,
    //                     "customerId": 5,
    //                     "propertyId": 3,
    //                     "noOfFlats": 7,
    //                     "created_at": "2024-03-24T17:57:28.000000Z",
    //                     "updated_at": "2024-03-24T17:57:28.000000Z",
    //                     "customer": {
    //                         "id": 5,
    //                         "firstname": "Solomon",
    //                         "lastname": "Guy",
    //                         "username": null,
    //                         "email": "sunmolasolomon@gmail.com",
    //                         "hasloggedin": "0",
    //                         "imgUrl": null,
    //                         "phone": "09167657581",
    //                         "otp": null,
    //                         "totalOverdueBill": "0.00",
    //                         "role": 1,
    //                         "created_at": "2024-03-24T17:43:12.000000Z",
    //                         "updated_at": "2024-03-24T17:44:08.000000Z"
    //                     }
    //                 }
    //             ]
    //         }
    //     ]
    // }
    return (
        <main className='px-4'>
            <div className="flex flex-col lg:flex-row justify-between my-10">
                {/* <div className="w-full lg:w-[45%]">
                    <AdminCard heading={"Total Customers"} amount={"300"} icon={<IoPeople className='text-green w-10 h-8' />} />
                </div> */}
                <div className="w-full mt-5 lg:mt-0 ">
                    <AdminCard heading={"Total Property"} amount={"300"} icon={<FaHouse className='text-green w-10 h-6 mt-1' />} />
                </div>
            </div>

            <div className="bg-lightBackground py-5 rounded-3xl shadow-2xl">
                <Paragraph2XlGreenMargin content={"Property Records"} />
                <div className="overflow-x-auto font-Poppins mt-3">
                    <Table striped >
                        <TableHead className=" font-Poppins text-sm capitalize text-white bg-green dark:text-white">
                            <TableHeadCell>Property ID</TableHeadCell>
                            <TableHeadCell>Property address</TableHeadCell>
                            <TableHeadCell>LGA</TableHeadCell>
                            <TableHeadCell>Ward</TableHeadCell>
                            <TableHeadCell>Property code</TableHeadCell>
                            <TableHeadCell>Number of customer</TableHeadCell>
                            <TableHeadCell>Customer details</TableHeadCell>
                            <TableHeadCell>Action</TableHeadCell>
                        </TableHead>
                        <TableBody className="divide-y font-Poppins ">
                            {currentTransactions.map((item, index) => (
                                <TableRow className={index % 2 === 0 ? "odd:bg-tableBackground" : "bg-white dark:border-gray-700 dark:bg-gray-800"} key={index}>
                                    <TableCell>{item.id}</TableCell>
                                    <TableCell>{item.propertyAddress} </TableCell>
                                    <TableCell>{item.lgaName} </TableCell>
                                    <TableCell>{item.wardName}</TableCell>
                                    <TableCell>{item.propertyCode}</TableCell>
                                    <TableCell>{item.customer_properties.length}</TableCell>
                                    <TableCell>
                                        <ButtonsPrimary name={"View More"} onClick={() => handleToggleOpen(item.customer_properties, item.propertyTypeName)}/>

                                    </TableCell>
                                    <TableCell> ... </TableCell>
                                </TableRow>
                            ))}

                            <CustomerModal openModal={openModal} setOpenModal={setOpenModal} customer={selectedCustomer} type={selectedCustomerPType} />

                        </TableBody>

                    </Table>
                    {/* Pagination */}
                    <div className="flex justify-end items-center h-20 my-10 w-1/4 ">
                        <div className="w-full h-10 border border-primary2 flex items-center justify-end rounded-md px-5">
                            <button
                                className={` `}
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <div className="ml-4 h-full">
                                {AllUsers &&
                                    AllUsers.length &&
                                    Array.from({
                                        length: Math.ceil(
                                            AllUsers.length / transactionsPerPage
                                        ),
                                    }).map((_, index) => (
                                        <button
                                            className={`w-8 h-full  ${currentPage === index + 1
                                                ? "bg-green text-white "
                                                : ""
                                                } mr-2`}
                                            key={index}
                                            onClick={() => paginate(index + 1)}
                                        >
                                            {index + 1}
                                        </button>
                                    ))}
                            </div>
                            <button
                                className={`ml-3 `}
                                onClick={() => paginate(currentPage + 1)}
                                disabled={
                                    !AllUsers ||
                                    currentPage ===
                                    Math.ceil(
                                        (AllUsers?.length || 0) / transactionsPerPage
                                    )
                                }
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Records