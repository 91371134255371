import React from 'react'

export const AlternateLogos = () => {
  return (
    <img src='/Images/logo.png' className='w-112 h-16 md:w-36 md:h-10' alt='logo' />
  )
}
export const RoundedLogo = () => {
  return (
    <img src='/Images/logo.png' className='w-16 h-16 ' alt='logo' />
  )
}
export const AdminLogo = () => {
  return (
    <img src='/Images/logo.png' className='w-20 h-20' alt='logo' />
  )
}
export const AdminLogo2 = () => {
  return (
    <img src='/Images/logo.png' className='' alt='logo' />
  )
}
