import React from 'react'

 export const Logo = () => {
  return (
    <img src='/Images/logo.png' className='w-full h-full ' alt='logo' />
  )
}
 export const LogoTrans = () => {
  return (
    <img src='/Images/logoTransparent.png' className='w-full h-full ' alt='logo' />
  )
}
 export const LogoWhite = () => {
  return (
    <img src='/Images/logoWhite.png' className='w-full h-full ' alt='logo' />
  )
}
