import React from 'react'

export const ParagraphSmall = ({content}) => {
  return (
    <p className='text-sm font-Poppins font-medium not-italic text-gray leading-6 m-4'>{content}</p>
  )
}
export const ParagraphSmallBlack = ({content}) => {
  return (
    <p className='text-sm font-Poppins font-normal not-italic text-black leading-6 m-4'>{content}</p>
  )
}
export const ParagraphSmall2 = ({content}) => {
  return (
    <p className='text-sm m-2 font-Poppins font-light not-italic text-gray leading-6 '>{content}</p>
  )
}
export const ParagraphSmall2White = ({content}) => {
  return (
    <p className='text-sm m-2 font-Poppins font-light not-italic text-white leading-6 '>{content}</p>
  )
}
export const Paragraphbase2White = ({content}) => {
  return (
    <p className='text-base my-2 font-Poppins font-light italic text-white leading-6 '>{content}</p>
  )
}
export const ParagraphExtraSmall = ({content}) => {
  return (
    <p className='text-xxs font-Poppins font-thin not-italic text-white leading-2'>{content}</p>
  )
}
export const ParagraphExtraSmallError = ({content}) => {
  return (
    <p className='text-sm font-Poppins font-thin not-italic text-error text-center leading-2 m-1'>{content}</p>
  )
}

export const Paragraph = ({content}) => {
  return (
    <p className='text-base font-inter font-medium not-italic text-gray leading-6 m-4'>{content}</p>
  )
}
export const ParagraphTextSettings = ({content}) => {
  return (
    <p className='text-base font-inter font-thin not-italic text-gray leading-6 m-4'>{content}</p>
  )
}

export const ParagraphSettings = ({content}) => {
  return (
    <p className='text-2xl font-inter font-medium not-italic text-gray leading-6 m-4'>{content}</p>
  )
}
export const ParagraphBlack = ({content}) => {
  return (
    <p className='text-base font-inter font-medium not-italic text-black leading-6 m-4'>{content}</p>
  )
}

export const ParagraphNoMargin = ({content}) => {
  return (
    <p className='text-base font-inter font-medium not-italic text-gray leading-6'>{content}</p>
  )
}

export const Paragraph2 = ({content}) => {
  return (
    <p className='text-base m-2 font-inter font-bold not-italic text-gray leading-6'>{content}</p>
  )
}

export const Paragraph2Green = ({content}) => {
  return (
    <p className='text-base m-2 font-inter font-bold not-italic text-green leading-6'>{content}</p>
  )
}
export const ParagraphXl = ({content}) => {
  return (
    <p className='text-xl font-inter font-semibold not-italic text-gray leading-6 m-4'>{content}</p>
  )
}
export const ParagraphXlWhite = ({content}) => {
  return (
    <p className='text-xl font-inter font-semibold not-italic text-white leading-6'>{content}</p>
  )
}

export const ParagraphXlGreenNoMargin = ({content}) => {
  return (
    <p className='text-xl font-inter font-semibold not-italic text-green leading-6 m-1'>{content}</p>
  )
}

export const Paragraph2Xl = ({content}) => {
  return (
    <p className='text-2xl font-Poppins font-bold not-italic text-black leading-8 m-4'>{content}</p>
  )
}
export const Paragraph2XlWhite = ({content}) => {
  return (
    <p className='text-2xl font-Poppins font-semibold not-italic text-white leading-8 m-4'>{content}</p>
  )
}
export const Paragraph2XlGreen = ({content}) => {
  return (
    <p className='text-2xl font-Poppins font-semibold not-italic text-green leading-6'>{content}</p>
  )
}
export const Paragraph2XlGreenMargin = ({content}) => {
  return (
    <p className='text-2xl font-Poppins font-semibold not-italic text-green leading-6 m-4'>{content}</p>
  )
}

export const ParagraphLarge = ({content}) => {
  return (
    <p className='text-3xl font-Poppins font-semibold not-italic text-black leading-8 m-4'>{content}</p>
  )
}
export const ParagraphLargeHome = ({content, name}) => {
  return (
    <p className='text-3xl font-Poppins font-normal not-italic text-black leading-15 m-4'>{content} <span className='font-bold text-green'>{name}</span></p>
  )
}
export const ParagraphLargeGreen = ({content}) => {
  return (
    <p className='text-3xl font-Poppins font-semibold not-italic text-green leading-8 m-4'>{content}</p>
  )
}
export const ParagraphLargeNoMargin = ({content}) => {
  return (
    <p className='text-3xl font-Poppins font-semibold not-italic text-black leading-8'>{content}</p>
  )
}
export const ParagraphLargeMarginItalic = ({content}) => {
  return (
    <p className='text-3xl font-Poppins font-thin italic text-black leading-8 m-4'>{content}</p>
  )
}

export const ParagraphLargeWhite = ({content}) => {
  return (
    <p className='text-3xl font-Poppins font-semibold not-italic text-white leading-8 m-4'>{content}</p>
  )
}
export const ParagraphLargeWhite4xl = ({content}) => {
  return (
    <p className='text-4xl font-Poppins font-bold not-italic text-black leading-8 m-4'>{content}</p>
  )
}