import React from 'react'
import { RiBarChart2Fill } from "react-icons/ri";
import { FaHouse } from "react-icons/fa6";
import { BiTransfer } from "react-icons/bi";
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from 'flowbite-react';
import { Paragraph2XlGreen, Paragraph2XlGreenMargin, ParagraphLargeGreen, ParagraphXlGreenNoMargin } from '../../../Component/Paragraph'
import AdminCard from '../../../Component/Card/AdminCard'

const Dash = () => {
    const operator =  JSON.parse(sessionStorage.getItem('AUTH_ADMIN_DETAILS'));
    console.log(operator);
    return (
        <main className='px-4'>
            <div className="flex flex-col lg:flex-row gap-5">
                <div className="lg:w-[80%]">
                    <ParagraphXlGreenNoMargin content={`Welcome to Wasteefy Management System (${operator ? operator.firstname : 'Name'})`} />
                    <div className="">
                        <p className='text-base font-inter font-medium not-italic text-green leading-6 m-1'>Hi,  Welcome  <span className='text-grayRounded'> { operator ? operator.businessName : "Business Name"}.</span> back</p>
                    </div>
                </div>
                <div className="lg:w-[20%] flex flex-col justify-end text-end">
                    <ParagraphXlGreenNoMargin content={"23 January 2023"} />
                    <ParagraphXlGreenNoMargin content={"11:30PM"} />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-between my-10 gap-12">
                <div className="w-full lg:w-1/3">
                    <AdminCard heading={"Total Revenue"} amount={"N100,000,000.00"} icon={<RiBarChart2Fill className='text-green w-10 h-8' />} />
                </div>
                <div className="w-full lg:w-1/3">
                    <AdminCard heading={"Total Property"} amount={"500"} icon={<FaHouse className='text-green w-10 h-7 mt-1' />} />
                </div>
                <div className="w-full lg:w-1/3">
                    <AdminCard heading={"Total Overdue Payments"} amount={"N300,000.00"} icon={<BiTransfer className='text-green w-10 h-7 mt-1' />} />
                </div>
            </div>


            {/* recent transaction  */}
            <div className="bg-lightBackground py-5 rounded-3xl shadow-2xl">
                <Paragraph2XlGreenMargin content={"Recent Transactions"} />
                <div className="overflow-x-auto font-Poppins mt-3">
                    <Table striped >
                        <TableHead className=" font-Poppins text-sm capitalize text-white bg-green dark:text-white">
                            <TableHeadCell>Date & Time</TableHeadCell>
                            <TableHeadCell>Username</TableHeadCell>
                            <TableHeadCell>Address</TableHeadCell>
                            <TableHeadCell>Amount</TableHeadCell>
                            <TableHeadCell>Bill Type</TableHeadCell>
                            <TableHeadCell>Mode of Payment</TableHeadCell>
                            <TableHeadCell>Status</TableHeadCell>
                            <TableHeadCell>Total Overdue
                                Bills
                            </TableHeadCell>
                        </TableHead>
                        <TableBody className="divide-y font-Poppins ">
                            <TableRow className="odd:bg-tableBackground">
                           
                                <TableCell>Today,10:30PM</TableCell>
                                <TableCell>Olankunle_22</TableCell>
                                <TableCell>34 Sunrise Avenue, Agege...</TableCell>
                                <TableCell>N13,100.00</TableCell>
                                <TableCell>Current bill</TableCell>
                                <TableCell>debit card</TableCell>
                                <TableCell>
                                    <span className='text-success'>Succesful</span>
                                </TableCell>
                                <TableCell>
                                    Cleared
                                </TableCell>

                            </TableRow>
                            <TableRow className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <TableCell>
                                    02, Mar 23,11:10PM
                                </TableCell>
                                <TableCell>Segun_10</TableCell>
                                <TableCell>34 Sunrise Avenue, Agege...</TableCell>
                                <TableCell>N57,100.00</TableCell>
                                <TableCell>Current bill</TableCell>
                                <TableCell>bank transfer</TableCell>
                                <TableCell>
                                    <span className='text-pending'>Pending</span>
                                </TableCell>
                                <TableCell>Cleared</TableCell>
                            </TableRow>

                            <TableRow className="odd:bg-tableBackground">
                           
                                <TableCell>10, Mar 23, 04:10PM</TableCell>
                                <TableCell>Olankunle_22</TableCell>
                                <TableCell>34 Sunrise Avenue, Agege...</TableCell>
                                <TableCell>N100,100.00</TableCell>
                                <TableCell>Current bill</TableCell>
                                <TableCell>debit card</TableCell>
                                <TableCell>
                                    <span className='text-failed'>Failed</span>
                                </TableCell>
                                <TableCell>
                                N75,000.00
                                </TableCell>

                            </TableRow>
                            <TableRow className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <TableCell>
                                    02, Mar 23,11:10PM
                                </TableCell>
                                <TableCell>Segun_10</TableCell>
                                <TableCell>34 Sunrise Avenue, Agege...</TableCell>
                                <TableCell>N57,100.00</TableCell>
                                <TableCell>Current bill</TableCell>
                                <TableCell>bank transfer</TableCell>
                                <TableCell>
                                    <span className='text-success'>Succesful</span>
                                </TableCell>
                                <TableCell>Cleared</TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </div>
            </div>
        </main>
    )
}

export default Dash