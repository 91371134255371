import React from 'react'
import { Button, Modal } from "flowbite-react";
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from 'flowbite-react';
import { ButtonsPrimary, ButtonsPrimaryBlack } from '../Buttons';

const CustomerModal = ({ openModal, setOpenModal, customer, type }) => {
    return (
        <Modal show={openModal} onClose={() => setOpenModal(false)} size={'2xl'}>
            <Modal.Header>Customer Details</Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                    <Table striped >
                        <TableHead className=" font-Poppins text-sm capitalize text-white bg-black dark:text-white">
                            <TableHeadCell>User ID</TableHeadCell>
                            <TableHeadCell>Name</TableHeadCell>
                            <TableHeadCell>PropertyType</TableHeadCell>
                            <TableHeadCell>Unit</TableHeadCell>
                            
                            <TableHeadCell>Total overdue Bills</TableHeadCell>
                        </TableHead>
                        <TableBody className="divide-y font-Poppins ">
                            {customer && customer.map((item, index) => (
                                <TableRow className={index % 2 === 0 ? "odd:bg-tableBackground" : "bg-white dark:border-gray-700 dark:bg-gray-800"} key={index}>
                                    <TableCell>{item.customer.id}</TableCell>
                                    <TableCell>{item.customer.firstname} {item.customer.lastname} </TableCell>
                                    <TableCell>{type} </TableCell>
                                    <TableCell>{item.noOfFlats}</TableCell>
                             
                                    <TableCell>{item.customer.totalOverdueBill}</TableCell>
                                   
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className=" w-1/3">
                <ButtonsPrimaryBlack onClick={() => setOpenModal(false)} name={"Done"} />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default CustomerModal
