import React, { useState, useEffect, useRef } from 'react'
import { IoChevronBack } from "react-icons/io5";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { Paragraph2Xl, ParagraphExtraSmallError, ParagraphLargeWhite, ParagraphSmall } from '../../Component/Paragraph';
import { ButtonsPrimary } from '../../Component/Buttons';
import { InputFieldSecondaryGreen } from '../../Component/InputField';
import baseURL from '../../utils/apiConfig';

const validate = values => {
    const errors = {};
    // if (!values.propertyAddress) {
    //     errors.propertyAddress = 'Property Address is Required';
    // }
    if (!values.pspOperatorId) {
        errors.pspOperatorId = 'PSP Operator is Required';
    }

    return errors;
};

const initialAddress = {
    propertyAddress: "",
    propertylatitude: "",
    propertylongitude: ""
  };
const PropertyAccount = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedState, setSelectedState] = useState(null);
    const [selectedLGA, setSelectedLGA] = useState(null);
    const [allState, setAllState] = useState([]);
    const [StateSelectedID, setStateSelectedID] = useState();
    const [LgaSelectedID, setLgaSelectedID] = useState();
    const [WardSelectedID, setWardSelectedID] = useState();
    const [PspOperatorList, setPspOperatorList] = useState([]);
    const [loading, SetLoading] = useState(false);
    const [loadingPSP, SetLoadingPSP] = useState(false);
    const [addressValues, SetAddressValues ] = useState(initialAddress);


    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        componentRestrictions: { country: "ng" },
        fields: ["address_components", "geometry", "icon", "name", "formatted_address"],
        types: ["geocode"]
    };
    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            options
        );

        autoCompleteRef.current.addListener('place_changed', function() {
            const place = autoCompleteRef.current.getPlace();
    
            if (!place.geometry) {
                console.log("No geometry available for the selected place.");
                return;
            }
    
            const latitude = place.geometry.location.lat().toString();
            const longitude = place.geometry.location.lng().toString();
            const address = place.formatted_address;

            SetAddressValues(prevState => ({
                ...prevState,
                "propertyAddress": address,
                "propertylatitude": latitude,
                "propertylongitude": longitude
              }));
    
            console.log(`Address: ${address}, Latitude: ${latitude}, Longitude: ${longitude}`);
        });

    }, []);

    const propertyState = location.state && location.state.data;

    const someValues = {
        ...propertyState,
        stateId: StateSelectedID,
        lgaId: LgaSelectedID,
        wardId: WardSelectedID,
        phone: localStorage.getItem('phone'),
        propertyAddress: addressValues.propertyAddress,
        pLatitude: addressValues.propertylatitude,
        pLongitude: addressValues.propertylongitude

    }


    const formik = useFormik({
        initialValues: {
            // propertyAddress: '',
            pspOperatorId: '',
        },
        validate,
        onSubmit: values => {
            SetLoading(true)
            const data = {
                ...values,
                ...someValues
            }
            // console.log(data);
            // SetLoading(false)
            // navigate("/propertyreview", { state: { data: data } })

            axios.post(baseURL + 'auth/customer/signup/create-property', data, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    // console.log(res);
                    SetLoading(false)
                    const message = res.data?.message;
                    const errorState = res.data?.errorState;
                    const result = res.data?.data;
                    // console.log(result);
                    if (!errorState) {
                        toast.success(message, { autoClose: 5000 });
                        setTimeout(() => {
                            navigate("/propertyreview", { state: { data: result } })
                        }, 3000)
                    } else {
                        toast.error(message, { autoClose: 5000 });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    SetLoading(false)
                    const message = err.response.data?.message;
                    toast.error(message, { autoClose: 7000 });
                });

        },
    });

    //function to fetch all state
    const HandleFetchState = () => {
        axios.get(baseURL + 'state/state-lga-ward', {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                setAllState(res.data.data);

            })
            .catch((err) => {
                console.log(err);
            });
    };

    //handle state and LGA Changes
    const handleStateChange = (e) => {
        const stateId = parseInt(e.target.value, 10);
        setStateSelectedID(stateId);
        const selectedState = allState.find((state) => state.id === stateId);
        setSelectedState(selectedState);
        setSelectedLGA(null);
    };

    const handleLGAChange = (e) => {
        const lgaId = parseInt(e.target.value, 10);
        setLgaSelectedID(lgaId);
        const selectedLGA = selectedState.lgas.find((lga) => lga.id === lgaId);
        setSelectedLGA(selectedLGA);
    };

    const handleWardChange = (e) => {
        const wardId = parseInt(e.target.value, 10);
        setWardSelectedID(wardId);
        SetLoadingPSP(true)
        axios.get(baseURL + 'pspOperator/operator-ward/' + wardId, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                // console.log(res);
                setPspOperatorList(res.data?.data);
                SetLoadingPSP(false)

            })
            .catch((err) => {
                SetLoadingPSP(true)
                console.log(err);
            });
    };

    //end LGA and State change




    useEffect(() => {
        HandleFetchState()
    }, [])
    return (
        <main className="mt-4 mx-4 md:m-0 md:bg-green md:h-screen md:bg-bgdesktop-pattern md:bg-cover">
            <div className="md:mt-0 md:py-5 md:px-10 md:flex md:justify-between">
                <ToastContainer />
                <div className="hidden md:block md:p-10">
                    <ParagraphLargeWhite content={"Sign Up"} />
                </div>


                <div className="md:mt-1 md:mb-10 md:w-2/6 md:bg-white md:border-transparent md:p-5 md:rounded-lg">
                    <div className="flex justify-start mt-6 ml-6 md:mt-2 ">
                        <Link to="/propertyunit" smooth="true" duration={500}>
                            <IoChevronBack size={28} className='bg-green text-white rounded-2xl' />
                        </Link>
                    </div>



                    <div className="mt-4 mx-4 md:mt-2 md:text-center">
                        <Paragraph2Xl content={"Set Up Your Property Account"} />
                        <ParagraphSmall content={"Select your property address info to set up your account"} />
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mt-6 mx-4 md:mt-2">
                            <select id="" className="border-2 px-2 py-3 pl-5  bg-white shadow-sm rounded-lg block font-Poppins w-full h-14 text-green text-base mt-1 ring-gray  placeholder:text-green focus:outline-none focus:ring-2 focus:border-purple focus:border-transparent focus:ring-gray" name={"state"} onChange={handleStateChange}>
                                <option value="" >State</option>
                                {allState?.map((state) => (
                                    <option key={state.id} value={state.id}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mt-6 mx-4 md:mt-2">
                            <select name={"lga"} onChange={handleLGAChange} id="" className="border-2 px-2 py-3 pl-5  bg-white shadow-sm rounded-lg block font-Poppins w-full h-14 text-green text-base mt-1 ring-gray  placeholder:text-green focus:outline-none focus:ring-2 focus:border-purple focus:border-transparent focus:ring-gray">
                                <option value="" >LGA/LCDA</option>
                                {selectedState?.lgas.map((lga) => (
                                    <option key={lga.id} value={lga.id}>
                                        {lga.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mt-6 mx-4 md:mt-2">
                            <select name={"wardId"} onChange={handleWardChange} id="" className="border-2 px-2 py-3 pl-5  bg-white shadow-sm rounded-lg block font-Poppins w-full h-14 text-green text-base mt-1 ring-gray  placeholder:text-green focus:outline-none focus:ring-2 focus:border-purple focus:border-transparent focus:ring-gray">
                                <option value="" >Wards</option>
                                {selectedLGA?.wards.map((ward) => (
                                    <option key={ward.id} value={ward.id}>
                                        {ward.name}
                                    </option>
                                ))}
                            </select>
                            {formik.errors.wardId ? <ParagraphExtraSmallError content={formik.errors.wardId} /> : null}
                        </div>
                        {/* <div className="mt-6 mx-4 md:mt-2">

                            <InputFieldSecondaryGreen placeholder={"Property Address"} name={"propertyAddress"} onChange={formik.handleChange} value={formik.values.propertyAddress} />
                            {formik.errors.propertyAddress ? <ParagraphExtraSmallError content={formik.errors.propertyAddress} /> : null}
                        </div> */}
                        <div className="mt-6 mx-4 md:mt-2">
                            <input
                                placeholder='Property Address'
                                name={"propertyAddress"}
                                ref={inputRef}
                                className='border-2 px-2.5 py-3.5 pl-8  bg-white shadow-sm rounded-lg block font-Poppins w-full h-14 text-black text-base mt-1 ring-gray  placeholder:text-green focus:outline-none focus:ring-2 focus:border-purple focus:border-transparent focus:ring-gray'
                            />
                                 {formik.errors.propertyAddress ? <ParagraphExtraSmallError content={formik.errors.propertyAddress} /> : null}
                        </div>

                        <div className="mt-6 mx-4 md:mt-2">
                            <select name={"pspOperatorId"} onChange={formik.handleChange} value={formik.values.pspOperatorId} id="" className="border-2 px-2 py-3 pl-5  bg-white shadow-sm rounded-lg block font-Poppins w-full h-14 text-green text-base mt-1 ring-gray  placeholder:text-green focus:outline-none focus:ring-2 focus:border-purple focus:border-transparent focus:ring-gray">
                                <option value="" > {loadingPSP ? 'Loading...' : 'PSP Operator'}</option>
                                {PspOperatorList?.map((psp) => (
                                    <option key={psp.id} value={psp.id}>
                                        {psp.businessName}
                                    </option>
                                ))}

                            </select>
                            {
                                !loadingPSP && PspOperatorList?.length == 0 && <ParagraphExtraSmallError content={'No PSP Operator available for this ward.'} />
                            }
                            {formik.errors.pspOperatorId ? <ParagraphExtraSmallError content={formik.errors.pspOperatorId} /> : null}
                        </div>
                        <div className="my-10 mx-4 md:my-5">
                            <ButtonsPrimary name={"Generate property code"} type={"submit"} loading={loading} />
                        </div>
                    </form>
                </div>

            </div>

        </main>
    )
}

export default PropertyAccount